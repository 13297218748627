import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
// import slugify from 'url-slug';
import urlSlug from 'url-slug';
import styles from './LocationInfo.style';
import AddressCard from '../../molecules/AddressCard';
import ContactUsCard from '../../molecules/ContactUsCard';
import HoursOfOperation from '../../molecules/HoursOfOperation';
import constants from '../../../constants/globalConstants';
import { locationLabels } from '../../../constants/labels/en';
import { parseWorkingHours } from '../../../utils/utilityFunction';
import { peackingImageRenditions } from './config';
import StaticGoogleMaps from '../GoogleMaps/StaticGoogleMaps';
var LocationInfoBlock = styled.section.withConfig({
  componentId: "sc-16bws4v-0"
})(["", ";"], styles);
var LocationInfo = function LocationInfo(_ref) {
  var _locationInfoData$cta, _locationInfoData$cta2;
  var locationInfoData = _ref.locationInfoData,
    allYextData = _ref.allYextData,
    yextContactInfo = _ref.yextContactInfo,
    className = _ref.className,
    theme = _ref.theme,
    isContactPage = _ref.isContactPage;
  var _useState = useState(''),
    hoursBoxHeight = _useState[0],
    setHoursBoxHeight = _useState[1];
  var _useState2 = useState(null),
    staticMapMarginBottom = _useState2[0],
    setStaticMapMarginBottom = _useState2[1];
  var displayDynamicMap = locationInfoData.mapType === 'Dynamic';
  var yextResponse = allYextData;
  var yextContactInformationData = yextContactInfo;
  var hospitalName = yextContactInformationData && yextContactInformationData.name;
  var extendedHoursLabel = locationInfoData.extendedHoursLabel ? locationInfoData.extendedHoursLabel : locationLabels.extendedhours;
  var mainHoursLabel = locationInfoData.mainHoursLabel ? locationInfoData.mainHoursLabel : locationLabels.mainhours;
  var peekingImage = locationInfoData.peackingImage;
  var peekingImageSrcSetting = {
    imageRenditions: peackingImageRenditions
  };
  var idVal = locationInfoData.anchorLink ? urlSlug(locationInfoData.anchorLink) : null;
  var variation = locationInfoData.variation ? locationInfoData.variation.toLowerCase() : '';
  var appliedClass = variation === 'address-right' ? 'address-right' : '';
  var hours = yextContactInformationData && yextContactInformationData.hours;
  var exHours = yextContactInformationData && yextContactInformationData.c_extendedHours;
  var _parseWorkingHours = parseWorkingHours(yextContactInformationData && hours, yextContactInformationData && exHours),
    extendedHours = _parseWorkingHours.extendedHours,
    perDayHoursDetailArray = _parseWorkingHours.perDayHoursDetailArray,
    extendedHoursDetailArray = _parseWorkingHours.extendedHoursDetailArray,
    nameOfDay = _parseWorkingHours.nameOfDay;
  var imageUrl = peekingImage && peekingImage.image && peekingImage.image.url ? peekingImage.image.url : '';
  if (imageUrl === '') {
    imageUrl = peekingImage && peekingImage.image && peekingImage.image.file && peekingImage.image.file.url ? peekingImage.image.file.url : '';
  }
  var altText = peekingImage ? peekingImage.altText : '';
  var peekingImgTitle = peekingImage ? peekingImage.title || peekingImage.altText : '';
  var addressObject = {
    className: 'nva-location-info__small-box small-box panel',
    classNameHeading: 'nva-location-info__small-box-heading left-bar heading_four',
    classNamePara: 'nva-location-info__small-box-address address-block',
    HeadingType: 'h3',
    ariaLabel: locationLabels.address,
    headingText: locationLabels.address,
    address: yextContactInformationData && yextContactInformationData.address,
    googleMyBusinessCID: yextContactInformationData && yextContactInformationData.c_googleMyBusinessCID || '',
    // address2: fetchProperty(yextResponse, 'address2'),
    // city: fetchProperty(yextResponse, 'city'),
    // state: fetchProperty(yextResponse, 'state'),
    // zip: fetchProperty(yextResponse, 'zip'),
    peekingImageAttrs: {
      peekingSource: imageUrl,
      peekingMobileSource: imageUrl,
      peekingImageAltText: altText,
      peekingImageTitle: peekingImgTitle,
      peekingImageSrcSetting: peekingImageSrcSetting,
      imageOptimisationType: 'transparentBg',
      peekingImageClassName: 'peeking-image'
    },
    dataAnalytics: {
      type: constants.analytics.analyticsType.addressBox,
      value: '',
      variation: locationInfoData.variation
    }
  };
  var contactUsObject = {
    ariaLabel: locationLabels.contactus,
    headingText: locationLabels.contactus,
    phone: yextContactInformationData && yextContactInformationData.c_websitePhone || yextContactInformationData && yextContactInformationData.mainPhone,
    phoneText: locationLabels.phone,
    fax: yextContactInformationData && yextContactInformationData.fax,
    faxText: locationLabels.fax,
    email: yextContactInformationData && yextContactInformationData.emails,
    emailText: locationLabels.email,
    emergency: yextContactInformationData && yextContactInformationData.c_emergencyPhone,
    emergencyText: locationLabels.emergency,
    textPhoneText: locationLabels.sms,
    textPhone: yextContactInformationData && yextContactInformationData.c_textPhone,
    paraClassName: 'left'
  };
  var hoursOfOperationObject = {
    ariaLabel: locationLabels.hoursofoperation,
    headingText: locationLabels.hoursofoperation,
    mainHoursText: mainHoursLabel,
    extraHoursText: extendedHoursLabel,
    contactCardLink: locationInfoData.cta && locationInfoData.cta.url ? locationInfoData.cta.url : '',
    contactCardHyperlinkType: locationInfoData.cta && locationInfoData.cta.hyperlinkType ? locationInfoData.cta.hyperlinkType : '',
    contactCardLinkCopy: locationInfoData.cta && locationInfoData.cta.label ? locationInfoData.cta.label : '',
    contactCardBehavior: locationInfoData.cta && locationInfoData.cta.behavior ? locationInfoData.cta.behavior : '',
    contactCardSysId: locationInfoData === null || locationInfoData === void 0 ? void 0 : (_locationInfoData$cta = locationInfoData.cta) === null || _locationInfoData$cta === void 0 ? void 0 : (_locationInfoData$cta2 = _locationInfoData$cta.sys) === null || _locationInfoData$cta2 === void 0 ? void 0 : _locationInfoData$cta2.id,
    extendedHours: extendedHours,
    perDayHoursDetailArray: perDayHoursDetailArray,
    extendedHoursDetailArray: extendedHoursDetailArray,
    nameOfDay: nameOfDay,
    hoursTextLine: yextContactInformationData && yextContactInformationData.additionalHoursText,
    extendedHoursTextLine: yextContactInformationData && yextContactInformationData.c_extendedAdditionalHoursText,
    dataAnalytics: {
      type: constants.analytics.analyticsType.downloadInfoCTA,
      value: '',
      variation: locationInfoData.variation
    },
    holidayHours: yextContactInformationData && hours && hours.holidayHours
  };
  var hoursBoxRef = useCallback(function (node) {
    if (node && node.offsetHeight && typeof window !== 'undefined' && window.innerWidth) {
      setHoursBoxHeight(node.offsetHeight);
      if (!locationInfoData.snazzyMapsIframe || !(displayDynamicMap || locationInfoData.mapType == null)) {
        var hoursMarginTop = window.innerWidth / 20 < 80 ? window.innerWidth / 20 : 80;
        var staticMapHeight = getStaticMapHeight();
        var overFlowHours = parseInt(node.offsetHeight) + hoursMarginTop - staticMapHeight;
        var marginBottom = overFlowHours > 0 ? overFlowHours : null;
        setStaticMapMarginBottom(marginBottom);
      }
    }
  }, []);
  useEffect(function () {
    if (typeof window !== 'undefined') {
      var updateWindowDimensions = function updateWindowDimensions() {
        if (!locationInfoData.snazzyMapsIframe || !(displayDynamicMap || locationInfoData.mapType == null)) {
          var newWidth = window.innerWidth;
          var hoursMarginTop = newWidth / 20 < 80 ? newWidth / 20 : 80;
          var staticMapHeight = getStaticMapHeight();
          var overFlowHours = parseInt(hoursBoxHeight) + hoursMarginTop - staticMapHeight;
          var marginBottom = overFlowHours > 0 ? overFlowHours : null;
          setStaticMapMarginBottom(marginBottom);
        }
      };
      window.addEventListener('resize', updateWindowDimensions);
      return function () {
        return window.removeEventListener('resize', updateWindowDimensions);
      };
    }
  }, [hoursBoxRef, hoursBoxHeight]);
  var getStaticMapHeight = function getStaticMapHeight() {
    if (typeof window !== 'undefined' && window.innerWidth > 1280) {
      var windowInnerWidth = window.innerWidth > 1600 ? 1600 : window.innerWidth;
      return windowInnerWidth / 1280 * 734;
    }
    return 738;
  };
  if (!locationInfoData && !yextResponse) return false;
  return __jsx(LocationInfoBlock, {
    className: "nva-location-info ".concat(variation ? "nva-location-info--".concat(variation) : '', " ").concat(appliedClass, " box ").concat(className),
    id: idVal,
    mapHeight: hoursBoxHeight,
    staticMapMarginBottom: staticMapMarginBottom
  }, locationInfoData.snazzyMapsIframe && (displayDynamicMap || locationInfoData.mapType == null) ? __jsx("div", {
    className: "nva-location-info__maps-outer-wrapper snazzy-maps-iframe ".concat(contactUsObject.phone ? '' : 'default-height'),
    id: "hours-panel-map"
  }, __jsx("div", {
    className: "nva-location-info__map-wrapper mapWrapper",
    dangerouslySetInnerHTML: {
      __html: locationInfoData.snazzyMapsIframe
    }
  })) : __jsx("div", {
    className: "nva-location-info__map-outer-wrapper static-map",
    id: "hours-panel-map"
  }, __jsx("div", {
    className: "nva-location-info__map-wrapper mapWrapper"
  }, __jsx(StaticGoogleMaps, {
    address: yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.address,
    theme: theme
  }))), displayDynamicMap || locationInfoData.mapType == null ? (locationInfoData.showFloatingAddressBox || locationInfoData.showFloatingAddressBox == null) && __jsx(AddressCard, _extends({}, addressObject, {
    headerOverride: hospitalName,
    displayDirections: true
  })) : __jsx(AddressCard, _extends({}, addressObject, {
    headerOverride: hospitalName,
    displayDirections: true
  })), contactUsObject && contactUsObject.phone && __jsx("div", {
    className: "nva-location-info__big-box big-box panel col-md-offset-7 col-md-5 col-lg-4",
    ref: hoursBoxRef
  }, __jsx(ContactUsCard, _extends({
    className: "nva-location-info__contact-us-card panel-section"
  }, contactUsObject)), hoursOfOperationObject && __jsx(HoursOfOperation, _extends({
    isContactPage: isContactPage,
    className: "nva-location-info__hours-of-operation panel-section"
  }, hoursOfOperationObject))));
};
LocationInfo.defaultProps = {
  dataContentFul: '',
  inheritedstyles: {},
  aria: {},
  className: '',
  isContactPage: false
};
export default styled(LocationInfo).withConfig({
  componentId: "sc-16bws4v-1"
})(["", ";"], styles);